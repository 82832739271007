import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  pushEventsLog,
  saveCart,
  setSelectedVariantModalVisibility,
} from 'src/redux/actions';
import Modal from '../Common/Modal';
import IncDecButton from '../../components/Common/IncDecButton';
import { deviceWidth } from '@/utils/deviceWidth';
import { itemQuantityValueComparison } from '@/utils/itemQuantityValueComparison';
import { MAX_MOBILE_WIDTH } from '@/utils/constants';
import { mutateVariantItemWithCartData } from '@/utils/mutateVariantItemWithCartData';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import { IS_CLIENT } from '@/utils/checkRenderEnv';
import { fetchCatalogItemById } from '@/redux/actions';
import { useCartHandlers } from '../../hooks/useCartHandler';
import { savePDPData } from '../../redux/actions';

const VariantSelectedListModal = (props) => {
  const { handleIncreamentClick, handleDecreamentClick, handleSetProductData } =
    useCartHandlers();
  const dispatch = useDispatch();
  const [state, setState] = useState({ itemCartIndex: null });

  const {
    variant: { selectedVariantsModal },
    productLoaderId,
  } = useSelector((state) => state.commonReducer);
  const { userData, storeData, cartData, pdpData } = useSelector((state) => ({
    userData: state.userReducer,
    storeData: state.storeReducer.store,
    cartData: state.cartReducer,
    pdpData: state.catalogReducer.pdpData,
  }));

  useEffect(() => {
    if (selectedVariantsModal) {
      const productIndex = cartData.items.findIndex(
        (el) => el.item_id === productLoaderId
      );
      setState((state) => ({ ...state, itemCartIndex: productIndex }));
      if (productLoaderId) {
        dispatch(
          fetchCatalogItemById(
            { store_id: storeData?.store_id, item_id: productLoaderId },
            false,
            (data) => {
              handleSetProductData(data);
              dispatch(savePDPData(data));
            }
          )
        );
      }
      if (cartData && !cartData?.items?.[productIndex]) {
        onClose();
      }
    }
  }, [selectedVariantsModal, cartData, productLoaderId]);
  useEffect(() => {
    if (selectedVariantsModal) {
      if (
        document.getElementsByTagName('body')[0] &&
        document.getElementsByTagName('body')[0].style
      ) {
        document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
      }
    } else {
      if (
        document.getElementsByTagName('body')[0] &&
        document.getElementsByTagName('body')[0].style
      ) {
        document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
      }
    }
  }, [selectedVariantsModal]);

  const onClose = () => {
    dispatch(setSelectedVariantModalVisibility(false));
  };

  const handleAddRemoveItem = (type, variantType) => {
    dispatch(
      pushEventsLog({
        event_name: 'Cx_ATC',
        data: {
          store_id: storeData?.store_id,
          domain: storeData?.store_info?.domain,
          page: window.location.href,
          device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
          cx_number: userData?.data?.phone,
        },
      })
    );
    const newCart = mutateVariantItemWithCartData(
      type,
      cartData?.items?.[state.itemCartIndex],
      variantType,
      cartData
    );
    dispatch(saveCart(newCart));
  };
  const handleOnChange = (type, variant) => {
    if (pdpData?.product_personalization) {
      handleSetProductData(pdpData);
      IS_CLIENT && LocalStorageHelper.add('activeVariantId', variant?.variant_id);
      if (type === 'inc') {
        handleIncreamentClick(type, pdpData);
      } else if (type === 'dec') {
        handleDecreamentClick(type, pdpData);
      }
      dispatch(setSelectedVariantModalVisibility(false));
    } else {
      handleAddRemoveItem(type, variant);
    }
  };

  return (
    <Modal
      visible={selectedVariantsModal}
      customStyles={{
        paddingLeft: 0,
        paddingRight: 0,
        // maxWidth: deviceWidth <= 766 ? "100%" : 480,
      }}
      height="auto"
      animation={'slideUp'}
      className="bottom-modalVvariantSelectedListModal"
      onClose={onClose}
    >
      <span className="closeicon pointer" onClick={onClose}>
        <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
      </span>

      <section className="title">Choose Item to remove</section>

      <div className="item-list">
        {cartData?.items?.[state.itemCartIndex]?.variants_selected?.map(
          (variant, ind) => {
            return (
              <div className="item" key={`items-variant-${ind}`}>
                <div className="flex items-start justify-start">
                  {variant.images && (
                    <img
                      src={variant?.images?.[0]?.image_url}
                      className="img h3"
                      alt={`variant image ${ind + 1}`}
                    ></img>
                  )}
                  <article className="left ml2">
                    <div className="item-name">
                      {cartData?.items?.[state.itemCartIndex]?.name}
                    </div>
                    <div className="variant-name">{variant.variant_name}</div>
                  </article>
                </div>
                <article className="right" style={{ justifyContent: 'flex-end' }}>
                  <IncDecButton
                    disableAddition={itemQuantityValueComparison(variant)}
                    catalogCta={true}
                    showAddAction={1}
                    quantity={variant.quantity}
                    onChangeEventhandle={(change) => handleOnChange(change, variant)}
                  />
                </article>
              </div>
            );
          }
        )}
      </div>
    </Modal>
  );
};

export default VariantSelectedListModal;
